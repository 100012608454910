<template>
  <div>
    <bread-crumb
      :route-name="routeName"
    />
    <div
      class="container-fluid mt-4"
      :class="classContainer"
    >
      <b-card>
        <Tabs
          :count="count"
          :search="filter"
          :route-name="routeName"
          :key="'tabs-key' + tabsKey"
          :refresh-stats="refreshStats"
          @on:search:update="filter = $event"
          @on:tab:changed="status = $event.status"
        />
        <campaign-table
          :key="'component-key' + componentKey"
          :status="status"
          :search-input="filter"
          :route-name="routeName"
          @on:loaded="count = $event"
          @on:campaign-status:updated="refreshStats = !refreshStats"
        />
      </b-card>
    </div>
  </div>
</template>
<script>
import {ALL} from "@/classes/doinsport/Campaign";

export default {
  components: {
    Tabs: () => import("@views/marketing/Tabs"),
    BreadCrumb: () => import("@views/marketing/BreadCrumb"),
    CampaignTable : () => import("@views/marketing/content/CampaignTable"),
  },
  data: () => ({
    count: 0,
    items: [],
    tabsKey: 0,
    filter: '',
    status: ALL,
    componentKey: 0,
    refreshStats: false,
  }),
  props: {
    classContainer: {
      type: String,
      default: ''
    }
  },
  computed: {
    routeName() {
      return this.$route.name.split('_')[0];
    },
  },
  watch: {
    routeName: function () {
      this.tabsKey++;
      this.filter = '';
      this.status = ALL;
      this.componentKey++;
      this.$store.commit('menu/resetTabSelector', 'campaignsIndex');
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";
</style>
